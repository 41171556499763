import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { BsMap, BsEnvelope } from "react-icons/bs";
import { FaPhone } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Bize Ulaşın | Fesulkin 
			</title>
			<meta name={"description"} content={"Sorularınız, rezervasyonlarınız veya sadece son oyun hakkında sohbet etmek mi istiyorsunuz?"} />
			<meta property={"og:title"} content={"Bize Ulaşın | Fesulkin "} />
			<meta property={"og:description"} content={"Sorularınız, rezervasyonlarınız veya sadece son oyun hakkında sohbet etmek mi istiyorsunuz?"} />
			<meta property={"og:image"} content={"https://fesulkin.com/img/220221_PeterRentschler_PR4_4570-2-1024x682.jpg"} />
			<link rel={"shortcut icon"} href={"https://fesulkin.com/img/3285412.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fesulkin.com/img/3285412.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fesulkin.com/img/3285412.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fesulkin.com/img/3285412.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fesulkin.com/img/3285412.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fesulkin.com/img/3285412.png"} />
			<meta name={"msapplication-TileColor"} content={"https://fesulkin.com/img/3285412.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="120px 0 120px 0" background="linear-gradient(180deg,rgba(255, 255, 255, 0.8) 0%,rgba(255, 255, 255, 0.7) 100%) 0 0 no-repeat" quarkly-title="Contacts-7">
			<Box min-width="100px" min-height="100px">
				<Text
					as="h2"
					font="--headline1"
					md-font="--headline2"
					margin="20px 0 0 0"
					text-align="center"
				>
					Bize Ulaşın
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
					Sorularınız, rezervasyonlarınız veya sadece son oyun hakkında sohbet etmek mi istiyorsunuz?
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 0 0px"
				sm-grid-template-columns="1fr"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsMap}
						size="54px"
						margin="0px 0px 20px 0px"
						color="--dark"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--dark"
						md-margin="0px 0px 20px 0px"
					>
						Bizi ziyaret edin
					</Text>
					<Text
						margin="0px 0px 0 0px"
						color="--dark"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						md-margin="0px 0px 15px 0px"
					>
						Dinek, Emirgan Blv No:3,{" "}
						<br />
						07400 Alanya, Türkiye
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsEnvelope}
						size="54px"
						margin="0px 0px 20px 0px"
						color="--dark"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--dark"
						md-margin="0px 0px 20px 0px"
					>
						Bize bir mesaj atın
					</Text>
					<Link
						href="mailto:reserve@fesulkin.com"
						color="--dark"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						display="block"
						margin="0px 0px 0 0px"
						text-align="center"
					>
						reserve@fesulkin.com
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="fa"
						icon={FaPhone}
						size="54px"
						margin="0px 0px 20px 0px"
						color="--dark"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--dark"
						md-margin="0px 0px 20px 0px"
					>
						Bize bir seslenin
					</Text>
					<Link
						href="tel:+90 506 365 7541"
						color="--dark"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						display="block"
						text-align="center"
						margin="0px 0px 8px 0px"
					>
						+90 506 365 7541
					</Link>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});